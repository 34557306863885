import React, { useState, useEffect } from "react";
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from './index.module.less'
import { headerBg } from '@/images/experience/index'
import {
    ARSpace,
    SmartM,
    SimpleM,
    VirtualD
} from '@/components/Experiences/index'
import useGa from "@/hooks/useGa"
import { useIntl } from "react-intl";

const Index = () => {
    const intl = useIntl()
    const tabList = [
        { text: 'Air Casting', key: 'experience_AirCasting' },
        { text: 'Spatial Display', key: 'experience_SpatialDisplay' },
        { text: 'Virtual Desktop', key: 'experience_VirtualDesktop' },
        { text: 'AR Space', key: 'experience_ARSpace' },
    ]
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        if (window.location.search.includes('air-casting')) {
            setTabIndex(0)
        }
        if (window.location.search.includes('spatial-display')) {
            setTabIndex(1)
        }
        if (window.location.search.includes('virtual-desktop')) {
            setTabIndex(2)
        }
        if (window.location.search.includes('ar-space')) {
            setTabIndex(3)
        }
    }, [])
    return (
        <Layout footerBackground='#fff' footerTextWhite={false}>
            <SEO page="Experience" />
            <div className={style.head} style={{ backgroundImage: `url(${headerBg})` }}>
                <h1>{intl.formatMessage({ id: "experience_text", defaultMessage: "Experience" })}</h1>
            </div>
            <div className={style.content}>
                <div className={style.tab}>
                    <div className={style.tabList}>
                        {tabList.map((item, index) => (
                            <div className={style.tabListItem} key={item.key}
                                style={{
                                    color: tabIndex == index ? 'rgba(0, 0, 0, 0.8)' : "#fff",
                                    background: tabIndex == index ? '#fff' : 'transparent'
                                }}
                                onClick={() => {
                                    useGa('experience', item.key, 'Click');
                                    setTabIndex(index);
                                }}
                            >
                                {item.text}
                            </div>
                        ))}
                    </div>

                </div>

                {tabIndex == 0 && <SimpleM />}
                {tabIndex == 1 && <SmartM />}
                {tabIndex == 2 && <VirtualD />}
                {tabIndex == 3 && <ARSpace />}
            </div>
        </Layout>
    )
}

export default Index