import React, { useEffect, useState, useRef } from "react";
import style from './index.module.less'
import classNames from 'classnames'
import {
    threeTypeAnchor,
    threeTypeSmooth,
    threeTypeSide,
} from '@/images/experience/index'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"


const DofBox = () => {
    const [currentImg, setCurrentImage] = useState(1)
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState(0);
    const [dragEnd, setDragEnd] = useState(0);
    const containerRef = useRef(null);
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const picList = [
        {
            img: threeTypeAnchor,
            title: intl.formatMessage({ id: "beam_spatial_type1_title", defaultMessage: 'Body Anchor' }),
            desc: intl.formatMessage({ id: "beam_spatial_type1_desc", defaultMessage: " Degree of Freedom (3DoF) tracking allows the screen to remain still in the air regardless of head movements and move only with the body" })
        },
        {
            img: threeTypeSide,
            title: intl.formatMessage({ id: "beam_spatial_type3_title", defaultMessage: 'Sideview' }),
            desc: intl.formatMessage({ id: "beam_spatial_type3_desc", defaultMessage: "Shrinks the display and moves it to the side to avoid visual interference while still able to enjoy a show " })
        },
        {
            img: threeTypeSmooth,
            title: intl.formatMessage({ id: "beam_spatial_type2_title", defaultMessage: 'Smooth Follow' }),
            desc: intl.formatMessage({ id: "beam_spatial_type2_desc", defaultMessage: 'Designed to minimize image movements caused by physical environment, ideal for in-vehicle use' })
        }]
    useEffect(() => {
        if (!ifPc) {
            const interval = setInterval(() => {
                setCurrentImage((currentImg + 1) % 3);
            }, 10000);
            return () => clearInterval(interval);
        }

    }, [currentImg, ifPc]);
    useEffect(() => {
        if (!ifPc) {
            const container = containerRef.current;
            const handleDragStart = (event) => {
                setDragging(true);
                setDragStart(event.touches[0].clientX);
            };
            const handleDragMove = (event) => {
                if (!dragging) {
                    return;
                }
                setDragEnd(event.touches[0].clientX);
            };
            const handleDragEnd = () => {
                setDragging(false);
                const distance = dragStart - dragEnd;
                if (distance > 50) {
                    setCurrentImage((currentImg + 1) % 3);
                } else if (distance < -50) {
                    setCurrentImage((currentImg - 1 + 3) % 3);
                }
            };
            container.addEventListener('touchstart', handleDragStart);
            container.addEventListener('touchmove', handleDragMove);
            container.addEventListener('touchend', handleDragEnd);
            //   container.addEventListener('mousedown', handleDragStart);
            //   container.addEventListener('mouseup', handleDragEnd);

            return () => {
                container.removeEventListener('touchstart', handleDragStart);
                container.removeEventListener('touchmove', handleDragMove);
                container.removeEventListener('touchend', handleDragEnd);
                // container.removeEventListener('mousedown', handleDragStart);
                // container.removeEventListener('mouseup', handleDragEnd);
            };
        }

    }, [currentImg, dragStart, dragging, dragEnd, ifPc]);
    return (
        <div className={style.dof}>
            <h2 className={style.title}>
                {intl.formatMessage({ id: "beam_spatial", defaultMessage: "What Is Spatial Display " })}</h2>
            <div className={style.gif} ref={containerRef}>
                <div className={style.gifPlaceHolder}>
                    <div></div>
                </div>
                {picList.map((item, index) => (
                    <div
                        key={`experienceSmart${index}`}
                        className={classNames(style.gifBox,
                            currentImg == index ? style.gifBoxCenter :
                                (index === (currentImg - 1 + picList.length) % picList.length ? style.gifBoxLeft : style.gifBoxRight)
                        )}
                        onClick={() => ifPc && setCurrentImage(index)}
                    >

                        <div className={style.gifBoxImg} style={{ backgroundImage: `url(${item.img})` }}>
                            <div className={style.gifBoxImgCover}></div>
                        </div>

                        <h3 className={style.gifBoxTitle}>{item.title}</h3>
                    </div>
                ))}


            </div>
            <h4 className={style.dofText}>
                {picList[currentImg].desc}
            </h4>
        </div>
    )
}

export default DofBox




