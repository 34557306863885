import React from 'react'
import style from './index.module.less'
import { beam3dof } from '@/images/beam/index'
import { useIntl } from 'react-intl'

const ThreeDof = () => {
    const intl = useIntl()
    return (
        <div className={style.page}>
            <div className={style.content}>
                <div className={style.contentLeft}>
                    <h2 className={style.contentLeftTitle}>
                        {'3 DoF'}
                    </h2>
                    <h4 className={style.contentLeftDesc}>
                        {intl.formatMessage({ id: "experience_spatial_text4", defaultMessage: "Advanced computer technologies combining linear acceleration and angular velocity data to create the most comfortable viewing experience" })}
                    </h4>
                </div>
                <div className={style.contentRight}>
                    <img src={beam3dof} />
                </div>
            </div>
        </div>
    )
}

export default ThreeDof;