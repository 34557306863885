import React, { useRef, useEffect, useState } from "react"
import style from "./index.module.less"
import classNames from 'classnames'
import {
  smooth,
  nosmooth,
} from "@/images/experience/index"
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ModeShow = () => {
  const intl = useIntl()
  const ifPc = useBigScreen(768)
  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const lineRef = useRef(null)
  const conRef = useRef(null)
  const [mousePointer, setMousePointer] = useState(false)
  const [lineShow, setLineShow] = useState(true)

  let startFlag = false;
  useEffect(() => {
    const con = conRef.current
    const contentWidth = conRef.current.getBoundingClientRect().width;
    lineRef.current.style.transform = `translate(${contentWidth}px)`;
    lineRef.current.style.opacity = `1`;
    image2Ref.current.style.clipPath = `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`;

    ScrollTrigger.create({
      trigger: '.modeContent',
      start: "bottom bottom",
      onEnter: () => {
        let tween = gsap.timeline();
        tween.to(".modeLine", {
          x: contentWidth,
          duration: "0",
        }).to(".botSec", {
          "clip-path": `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
          duration: "0",
        }, '<').to(".modeLine", {
          x: 0,
          duration: "1.5",
          // delay: '1'
        }).to(".botSec", {
          "clip-path": `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
          duration: "1.5",
        }, '<').to(".modeLine", {
          x: contentWidth / 2,
          duration: "0.75",
        }).to(".botSec", {
          "clip-path": `polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%)`,
          duration: "0.75",
          // onComplete: function () {
          //   ScrollTrigger.getAll().forEach((trigger) => { 
          //      trigger.kill() 
          //   });
          // }
        }, '<')
      }
    })

    if (ifPc) {
      con.addEventListener("mousedown", handleMouseDown);
      document.addEventListener("mouseup", handleMouseUp);
      // con.addEventListener("mouseleave", handleMouseUp);
    } else {
      con.addEventListener("touchstart", handleTouchstart);
      document.addEventListener("touchend", handleTouchend);
    }
    return () => {
      if (ifPc) {
        con.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mouseup", handleMouseUp);
        // con.removeEventListener("mouseleave", handleMouseUp);
      } else {
        con.removeEventListener("touchstart", handleTouchstart);
        document.removeEventListener("touchend", handleTouchend);
      }
    }
  }, [ifPc])
   // pc端--鼠标按下
   const handleMouseDown = e => {
    setMousePointer(true);
    setStyle(e);
    conRef.current.addEventListener("mousemove", handleMouseMove);
   }
   // pc端--鼠标移动
  const handleMouseMove = e => {
    setMousePointer(true);
    setStyle(e);
  }
  const handleMouseEnter = e => {
    const image2 = image2Ref.current
    if(!!lineRef.current){
      lineRef.current.className = `${style.line} ${style.moveLeft}`
    }
    if(!!image2){
      image2.className = `${style.botSec} ${style.clipLeft}`
    }
    
  }
  const handleMouseLeave = e => {
    const image2 = image2Ref.current
    if(!!lineRef.current){
      lineRef.current.className = `${style.line} ${style.moveRight}`
    }
    if(!!image2){
       image2.className = `${style.botSec} ${style.clipRight}`
    }
   
  }

  // pc端--鼠标抬起
  const handleMouseUp = e => {
    setMousePointer(false);
    conRef.current.removeEventListener("mousemove", handleMouseMove);
  }

  // 移动端--按下
  const handleTouchstart = e => {
    setStyle(e?.targetTouches[0]);
    conRef.current.addEventListener("touchmove", handleTouchMove);
  }


  // 移动端--移动
  const handleTouchMove = e => {
    setStyle(e?.targetTouches[0]);
  }

  // 移动端--抬起
  const handleTouchend = e => {
    conRef.current.addEventListener("touchmove", handleTouchMove);
  }


  // 设置line和image2样式
  const setStyle = (e) => {

    const contentLeft = conRef.current.getBoundingClientRect().left;
    const contentWidth = conRef.current.getBoundingClientRect().width;
    let translateX = e.pageX - contentLeft;
    if (translateX < 20) {
      translateX = 0;
      setLineShow(false)
    } else if (translateX > contentWidth - 20) {
      translateX = contentWidth;
      setLineShow(false)
    } else {
      setLineShow(true)
    }
    const percent = translateX / contentWidth * 100;
    lineRef.current.style.transform = `translate(${translateX}px)`;
    image2Ref.current.style.clipPath = `polygon(0% 0%, ${percent}% 0%, ${percent}% 100%, 0% 100%)`;
  }


  return (
    <div className={style.box}>
      <h2 className={style.title}>
        {intl.formatMessage({ id: "experience_spatial_text5", defaultMessage: "Reduce Image Viberations with Smooth Follow" })}</h2>
      <div className={classNames(style.content, mousePointer ? style.mousePointer : '', 'modeContent')} ref={conRef}>
        <div className={style.botFirst} ref={image1Ref} style={{ backgroundImage: `url(${smooth})` }}>
          {/* <img className={style.botFirstImg} src={smooth} /> */}
          <div className={style.botFirstText}>ON</div>
          <div className={style.botFirstTexc}>Smooth Follow ON</div>
        </div>
        <div className={classNames(style.botSec, 'botSec')} ref={image2Ref} style={{ backgroundImage: `url(${nosmooth})` }}>
          {/* <img className={style.botSecImg} src={nosmooth} /> */}
          <div className={style.botSecText}>OFF</div>
          <div className={style.botSecTexc}>Smooth Follow OFF</div>
        </div>
        <div className={classNames(style.line, lineShow ? '' : style.lineHide, 'modeLine')} ref={lineRef}></div>
      </div>
    </div>
  )
}

export default ModeShow
