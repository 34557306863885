import PowerFull from '../SVG/powerFull.svg'
import powerNo from './powerNo.svg'
import PowerHalf from '../SVG/powerHalf.svg'
const powerVideo = `${process.env.resourceUrl}video/powerVideo.mp4`
// import beamPro from './beamPro.png'
// import beamProText from './beamProText.jpeg'
import beamLogo from './beamLogo.svg'
const beam_pro2 = `${process.env.resourceUrl}images/beam/beam_pro2.png`
const beamProExperience = `${process.env.resourceUrl}images/beam/beamProExperience.png`
const scroll_1 = `${process.env.resourceUrl}images/beam/scroll_1.jpg`
const scroll_2 = `${process.env.resourceUrl}images/beam/scroll_2.jpg`
const wired =`${process.env.resourceUrl}images/beam/Wire2.jpg`
const wireless =`${process.env.resourceUrl}images/beam/Wireless2.jpg`


const beam3dof = `${process.env.resourceUrl}images/experience/3dof.png`

const beamProText = `${process.env.resourceUrl}images/beam/beamProText.jpg`
const beamPro = `${process.env.resourceUrl}images/beam/beamPro.png`



//1024官网的新图片
const beamFirstBg_pc=`${process.env.resourceUrl}images/beam1024/proBg-pc.webp`
const beamFirstBg_mobile=`${process.env.resourceUrl}images/beam1024/proBg-mobile.webp`
const beamProLogo=`${process.env.resourceUrl}images/beam1024/beam-logo.svg`
const wired_beam=`${process.env.resourceUrl}images/beam1024/wired.webp`
const turn_beam=`${process.env.resourceUrl}images/beam1024/turn_beam.webp`
const beamBuyPro=`${process.env.resourceUrl}images/beam1024/beamBuy.webp`
const beamBuyPro_mobile=`${process.env.resourceUrl}images/beam1024/beamBuy_mobile.webp`
const beamIntro1=`${process.env.resourceUrl}images/beam1024/beamIntro1.svg`
const beamIntro2=`${process.env.resourceUrl}images/beam1024/beamIntro2.svg`
const beamIntro3=`${process.env.resourceUrl}images/beam1024/beamIntro3.svg`


export {
    beamIntro1,
    beamIntro2,
    beamIntro3,
    beamBuyPro_mobile,
   
    beamBuyPro,
    turn_beam,
    beamFirstBg_pc,
    beamFirstBg_mobile,
    beamProLogo,
    wired_beam,


    beamLogo,

    scroll_1,
    scroll_2,
    beam_pro2,
    beamProExperience,
    beam3dof,
  

    wired,
    wireless,
    PowerHalf,
    powerNo,
    PowerFull,
    powerVideo,
    beamPro,
    beamProText,
   
}