import React, { useEffect, useRef } from "react"
import Slider from "react-slick"
import "./index.css"
const SwiperPic = ({ picList }) => {
  useEffect(() => {
    let arrowLeft = document.querySelector(".experienceSwiper .slick-prev")

    let arrowRight = document.querySelector(".experienceSwiper .slick-next")

    if (!!arrowLeft) {
      arrowLeft.innerHTML = ""
    }
    if (!!arrowRight) {
      arrowRight.innerHTML = ""
    }
  }, [])
  let arrowLeft = document.querySelector(".experienceSwiper .slick-prev")

  let arrowRight = document.querySelector(".experienceSwiper .slick-next")

  if (!!arrowLeft) {
    arrowLeft.innerHTML = ""
  }
  if (!!arrowRight) {
    arrowRight.innerHTML = ""
  }
  const settings = {
    initialSlide: 0,
    // focusOnSelect: true,
    // swipeToSlide:true,
    arrows: false,
    dots: true,
    infinite: true,
    className: "experienceSwiper",
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    // mousewheel: true,
    autoplay: false,
    touchMove: true,
    dotsClass: "experienceSwiper-dots",
    customPaging: function (i) {
      return <a></a>
    },
    afterChange: function (i) {
      // setSwiperBg(i)
    },
  }
  const sliderRef = useRef(null)
  const handleSlideClick = index => {
    let picLen = picList.length
    let lasN = picLen - 1
    if (sliderRef.current) {
      const currentIndex = sliderRef.current.innerSlider.state.currentSlide
      if (index < currentIndex) {
        if (currentIndex == lasN && index != lasN - 1) {
          sliderRef.current.slickNext()
        } else {
          sliderRef.current.slickPrev()
        }
      } else if (index > currentIndex) {
        if (currentIndex == 0 && index != 1) {
          sliderRef.current.slickPrev()
        } else {
          sliderRef.current.slickNext()
        }
      }
    }
  }

  return (
    <div className="experienceSwiperBox">
      <Slider {...settings} ref={sliderRef}>
        {picList.map((item, index) => {
          return (
            <div
              key={`experience${index}`}
              onClick={() => handleSlideClick(index)}
            >
              <div
                className="experienceItem"
                style={{ backgroundImage: `url(${item})` }}
              >
                <div className="experienceItem-cover"></div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default SwiperPic
