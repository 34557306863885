import React from "react"
import style from "./index.module.less"
import styleCommon from "@/styles/commonset.module.less"

import { drm_1,drm_2,drm_3,drm_4,drm_5,drm_6,drm_7,drm_8} from "@/images/experience/index"
import { useIntl } from "react-intl"

const picAry = [drm_1,drm_2,drm_3,drm_4,drm_5,drm_6,drm_7,drm_8]
const Drm = () => {
  const intl=useIntl()
  return (
    <div className={style.box}>
      <h2 className={style.title}>{intl.formatMessage({ id: "experience_air_text10", defaultMessage: "Cohesive Support for DRM" })}</h2>
      <div className={style.flexBox}>
        {picAry.map((item, index) => (
          <div className={style.flexBoxItem} key={`flexBoxItem${index}`} style={{backgroundImage:`url(${item})`}}>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Drm
