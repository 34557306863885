import React from "react"
import style from "./index.module.less"
import styleCommon from "@/styles/commonset.module.less"

import { aircastSetup1, aircastSetup2 } from '@/images/experience/index'
import { useIntl } from "react-intl"


const EasySetup = () => {
  const intl = useIntl()
  const setupList = [
    {
      img: aircastSetup1,
      text: intl.formatMessage({ id: "compatibility_text1", defaultMessage: "Directly connect to any device with USB-C video output" }),
    },
    {
      img: aircastSetup2,
      text: intl.formatMessage({ id: "experience_air_text7", defaultMessage: "Use XREAL Adapter to connect to iPhone and devices with HDMI" }),
    },
    // {
    //     img:v3,
    //     text:"Get up to three virtual screens"
    // }
  ]
  return (
    <div className={style.box}>
      <h2 className={styleCommon.title}>{intl.formatMessage({ id: "experience_air_text4", defaultMessage: "Easy Setup" })}</h2>
      <h4 className={styleCommon.subtitle}>
        {intl.formatMessage({ id: "experience_air_text5", defaultMessage: "  Plug-and-play, no software needed." })}
      </h4>
      <div className={style.setup}>
        {setupList.map((item, index) => (
          <div className={style.setupItem} key={`setupItem${index}`}>
            <img className={style.setupItemImg} src={`${item.img}`} />
            <div className={style.setupItemText}>{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EasySetup
