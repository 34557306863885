import React from "react";
import style from './index.module.less'
import { s1, s2, s3, s4, s5, s6, s7, m1 } from '@/images/experience/index'
import DofBox from "./DofBox";
import ModeShow from "./ModeShow"
import ThreeDof from "./ThreeDof"
import ScrollPart from "./ScrollPart"
import SwiperPic from "../SwiperPic";
import { CompatibilityWire } from '@/components/index'


import { useIntl } from "react-intl";
const picList = [
    s1, s2, m1, s5, s6, s7
]
const SmartM = () => {
    const intl = useIntl()
    return (
        <div className={style.box}>
            <div className={style.fittingBox}>
                <h2 className={style.title}>
                    {intl.formatMessage({ id: "experience_spatial_text1", defaultMessage: "  Experience Mirroring Like Never Before" })}
                </h2>
                <h4 className={style.fittingBoxText}>
                    {intl.formatMessage({ id: "experience_spatial_text2", defaultMessage: "Built on XREAL's computer vision technology, Spatial Display takes screen mirroring to a whole other level." })}
                </h4>
            </div>

            <DofBox />
            <ThreeDof />

            <ModeShow />
            <ScrollPart />
            <SwiperPic picList={picList} />
            <div className={style.comp}>
                <div className={style.title}>{intl.formatMessage({ id: "support_how_connect", defaultMessage: "Compatibility" })}</div>
                <CompatibilityWire />
            </div>

        </div>
    )
}
export default SmartM