import React from "react"
import style from "./index.module.less"
import {
  odofGif,
} from "@/images/experience/index"
import { useIntl } from "react-intl"
const MirrorView = () => {
  const intl = useIntl()
  return (
   
    <div className={style.box}>
      <div className={style.title}>  {intl.formatMessage({ id: "experience_air_text3", defaultMessage: " What Is MirrorView" })}</div>
      {/* <div className={style.img}> */}
        <img className={style.img} src={odofGif}/>
      {/* </div> */}
    </div>
  )
}

export default MirrorView
