import React, { useEffect, useRef, useImperativeHandle, useMemo } from "react"
import { useLoader, useFrame } from "@react-three/fiber"
import * as THREE from "three"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { screenmodel } from '@/images/index'

export const Models = () => {
  const { nodes, scene } = useLoader(GLTFLoader, screenmodel)
  useMemo(() => Object.values(nodes).forEach(obj =>
    obj.isMesh && Object.assign(obj, { castShadow: true })), [nodes])
  const models = useRef(null)
  useFrame(() => {
    models.current.rotation.y += 0.0005
  })
  const groupRef = useRef(null)
  return (
    <group
      position={[0, 0, 1]}
      rotation={[0.07, 0, 0]}
      ref={groupRef}

    >
      <mesh>
        <directionalLight
          color={"#fff"}
          position={[0, 0, 300]}
          rotation={[0, 0, 0]}
          intensity={2}
        />
        <ambientLight color={"#fff"} intensity={1} />
        <primitive ref={models} object={scene} position={[0, 0, 0]} />
      </mesh>
    </group>

  )
}

export default Models
