import React from 'react'
import style from './index.module.less'
import {
    nebulaIcon, nebulaMacIcon, v4, windowIcon, macIcon
} from '@/images/experience/index'
import { nebulaVideo } from '@/images/nebula/index'
import { icon_get_it_on_google_2 } from '@/images/index'
import { ShiXinBaiBtn_updown,TouMingBaiBtn_updown } from '@/components/index'
import { useIntl } from 'react-intl'
import downloadfiles from "@/utils/downloadfile"
import useGa from "@/hooks/useGa";
import getCountry from '@/utils/getCountry.js'
import { getNrealapp } from '@/services/request'

const DownloadBox = ({ type }) => {
    const region = getCountry()

    const intl=useIntl()
    const macDownload = (link) => {
        useGa('experience','experience_DownloadMac','Click')
        getNrealapp({ currentVersionCode: 0, currentServiceModel: 1, currentPackageName: 'ai.nreal.nebula.mac' }).then((res) => {
            downloadfiles(res.data.data.latestVersion.packageUrl)
        })
    }
    const windowsDownload = () => {
        useGa('experience','experience_DownloadWindows','Click')
        getNrealapp({ currentVersionCode: 0, currentServiceModel: 1, currentPackageName: 'ai.nreal.nebula.windows' }).then((res) => {
            downloadfiles(res.data.data.latestVersion.packageUrl)
        })
    }
    return (
        <div className={style.download} >
            <div className={style.downloadBox}>
                <video autoPlay playsInline loop muted controls={false}>
                    <source src={nebulaVideo} type="video/mp4" />
                </video>
                <img src={type == 'pc' ? nebulaMacIcon : nebulaIcon} className={style.nebulaLogo} />
                <div className={style.downloadBoxRight}>
                    <div className={style.downloadBoxRightTitle}>
                        {type == 'pc' ? intl.formatMessage({ id: "download_nebula", defaultMessage: "Download Nebula" }): intl.formatMessage({ id: "download_nebula_android", defaultMessage:"Download Nebula for Android" })}
                    </div>
                    {type == 'pc' ?
                        <div className={style.downloadBtnBox}>
                            <ShiXinBaiBtn_updown styles={{ margin: '10px' }} onClick={macDownload}>
                                <div className={style.downloadBtn}>
                                    <img src={macIcon} className={style.macIcon}/><span>Nebula for Mac</span></div>
                            </ShiXinBaiBtn_updown>
                            {/* <ShiXinBaiBtn_updown styles={{ margin: '10px'}} onClick={windowsDownload}>
                                <div className={style.downloadBtn}>
                                    <img src={windowIcon} /><span>Nebula for Windows</span></div>
                            </ShiXinBaiBtn_updown> */}

                        </div>

                        :
                        <div className={style.downloadBtnBox}>{region=='th'?<TouMingBaiBtn_updown styles={{ border: `2px solid rgba(255,255,255,0.4)`, cursor: "auto" }} >

                        <div className={style.downloadItemBtn} style={{ color: 'rgba(255,255,255,0.4)',padding:"10px 20px" }}>
                            {intl.formatMessage({ id: "coming_sone_text", defaultMessage: "Coming soon" })}
                        </div>
                    </TouMingBaiBtn_updown>:<ShiXinBaiBtn_updown onClick={()=>{ useGa('experience','experience_DownloadAndroid','Click'),window.open("https://play.google.com/store/apps/details?id=ai.nreal.nebula.universal")}}>
                            <div className={style.downloadBtn} style={{ width: "147px", padding: "0", height: "44px" }}>
                                <img src={icon_get_it_on_google_2} className={style.googleImg} /></div>
                        </ShiXinBaiBtn_updown>}</div>}

                </div>
            </div>
        </div>)

}

export default DownloadBox