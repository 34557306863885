import React, { useEffect, useRef } from "react";
import style from './index.module.less'
import './index.module.less'
import {
    scroll_1,
    scroll_2,
    beam_pro2,
    beamProExperience
} from '@/images/beam/index'
import classNames from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl";

const ScrollPart = () => {
    const intl = useIntl()
    gsap.registerPlugin(ScrollTrigger)
    const scrollRef = useRef()
    const list = [
        {
            img: scroll_1,
            class: "scroll1"
        },
        {
            img: scroll_2,
            class: "scroll2"

        }
    ]
    const ifPc = useBigScreen(800)

    useEffect(() => {
        const outBoxes = gsap.utils.toArray('.scrollBoxItem');
        if (ifPc) {
            outBoxes.forEach(item => {
                ScrollTrigger.create({
                    trigger: scrollRef.current,
                    start: "45% center",
                    onEnter: () => {
                        gsap.to(item, {
                            marginTop: 0
                        })
                    },
                    onLeaveBack: () => {
                        gsap.to('.scrollBoxItem0', {
                            marginTop: '40px'
                        })
                        gsap.to('.scrollBoxItem1', {
                            marginTop: '120px'
                        })
                        gsap.to('.scrollBoxBeam', {
                            marginTop: '80px'
                        })
                    },

                })
            })
        }
    }, [])
    return (
        <div className={style.scroll}>
            <h2 className={style.title}>{intl.formatMessage({ id: "experience_spatial_text6", defaultMessage: "Bring Spatial Display to Everything with XREAL Beam" })}</h2>
            {ifPc ? <div className={style.scrollBox} ref={scrollRef}>
                {list.map((item, index) => (
                    <div key={`scrollBoxItem${index}`} className={classNames(style.scrollBoxItem, `scrollBoxItem`, `scrollBoxItem${index}`)} style={{ marginTop: ifPc ? `${index * 80 + 40}px` : "0", backgroundImage: `url(${item.img})` }}>
                    </div>
                ))}
                <div className={classNames(style.scrollBoxBeam, `scrollBoxBeam`, `scrollBoxItem`)} style={{ backgroundImage: `url(${beam_pro2})` }}>
                </div>

            </div> :
                <div className={style.scrollBoxM}>
                    <img className={style.scrollBoxMBeam} src={beamProExperience}>
                    </img>
                    {list.map((item, index) => (
                        <div key={`scrollBoxItemM${index}`} className={style.scrollBoxMItem} style={{ backgroundImage: `url(${item.img})` }}>
                        </div>
                    ))}

                </div>}

        </div>
    )
}

export default ScrollPart