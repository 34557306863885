import React from "react"
import style from "./index.module.less"
import styleCommon from "@/styles/commonset.module.less"

import { projector, vr, xrealair } from "@/images/experience/index"
import { useIntl } from "react-intl"


// ,
//   {
//       img:v3,
//       text:"Get up to three virtual screens"
//   }
const EnjoyAr = () => {
  const intl = useIntl()
  const picList = [
    {
      img: projector,
      text: intl.formatMessage({ id: "experience_air_text9", defaultMessage: "Projector" }),
    },
    {
      img: vr,
      text: "VR",
    }
  ]
  return (
    <div className={style.box}>
      <h2 className={style.title}>{intl.formatMessage({ id: "experience_air_text8", defaultMessage: "Enjoy The Best AR Optic Technology" })}</h2>
      <div className={style.top}>
        {picList.map((item, index) => (
          <div className={style.topItem} key={`setupItem${index}`}>
            <img className={style.topItemImg} src={`${item.img}`} />
            <h3 className={style.topItemText}>{item.text}</h3>
          </div>
        ))}
      </div>
      <div className={style.bot}>
        <img className={style.botImg} src={xrealair} />
        <h3 className={style.botText}>XREAL Air</h3>
      </div>
    </div>
  )
}

export default EnjoyAr
