import React, { useEffect, useState, useRef } from "react";
import style from './index.module.less'
import {
    v1,
    v2,
    v3,
    v4,
    desktopPic
} from '@/images/experience/index'
import {
    windowIcon,
    macIcon
} from '@/images/index'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import { DownloadBox } from "../index";
import { CompatibilitySupport } from '@/components/index'

import { useIntl } from "react-intl";

const VirtualD = () => {
    const intl = useIntl()
    gsap.registerPlugin(ScrollTrigger)
    const [showMask, setshowMask] = useState(false)
    const modesRef = useRef()
    const ifPc = useBigScreen(768)
    const compList = [
        {

            device: {
                img: windowIcon,
                name: 'Windows'
            },
            support: [
                {
                    // type:"dp",
                    comingsoon: true,
                    desc: intl.formatMessage({ id: "coming_sone_text", defaultMessage: "Coming soon" })
                    // desc:"<span>Coming Soon</span>"
                }
            ]
            // support: [
            //     {
            //         type: "mac",
            //         desc: intl.formatMessage({ id: "air_use_windows", defaultMessage: "<span>NVIDIA GPU</span>laptops" })
            //     },

            // ],
            // tips: [
            //     intl.formatMessage({ id: "air_use_windows_tip3", defaultMessage: '* Windows 10 or 11 64-bit)' }),
            //     intl.formatMessage({ id: "air_use_windows_tip1", defaultMessage: '* Nvidia GTX 1650 (Recommended: RTX3060 and above)' }),
            //    intl.formatMessage({ id: "air_use_windows_tip2", defaultMessage: '* USB-C DisplayPort (DP 1.2)' }),
            //     intl.formatMessage({ id: "air_use_windows_tip4", defaultMessage: "* Please read the <a href='https://www.reddit.com/r/Xreal/wiki/index/nebula/nebulaforwindows/' target='_blank'>FAQ</a> before use"})
            // ]

        },
        {
            device: {
                img: macIcon,
                name: 'MacBook'
            },
            support: [
                {
                    type: "mac",
                    desc: intl.formatMessage({ id: "air_use_mac", defaultMessage: "<span>M1/M2</span>MacBook" })
                }
            ],
            tips: [
                intl.formatMessage({ id: "air_use_mac_tip1", defaultMessage: '* macOS 12.0 and above' }), intl.formatMessage({ id: "air_use_mac_tip2", defaultMessage: '* 2019 and later Macbook models' })
            ]
        }
    ]
    const useList = [
        {
            img: v1,
            text: intl.formatMessage({ id: "experience_desktop_text4", defaultMessage: " Bring your workstation you on the road " })
        },
        {
            img: v2,
            text: intl.formatMessage({ id: "experience_desktop_text5", defaultMessage: "Don't let physical space limit your vision" })
        }
    ]
    useEffect(() => {
        let modesTween = gsap.timeline()
        modesTween.to(modesRef.current, {
            scrollTrigger: {
                trigger: modesRef.current,
                start: ifPc ? "bottom bottom" : "center bottom",
                onEnter: () => {
                    setshowMask(true)
                },
            }
        })
            .to(modesRef.current, {
                scrollTrigger: {
                    trigger: modesRef.current,
                    start: "top bottom",
                    onLeaveBack: () => { setshowMask(false) },
                }
            })
    }, [ifPc])
    return (
        <div className={style.box}>
            <div className={style.fittingBox}>
                <div className={style.title}>
                    {intl.formatMessage({ id: "experience_desktop_text1", defaultMessage: " Dream Can Be True" })}
                </div>
                <div className={style.subTitle}>
                    {intl.formatMessage({ id: "experience_desktop_text2", defaultMessage: " Biggest Screen with Smallest Laptop" })}

                </div>
                <div className={style.fittingBoxText}>
                    {intl.formatMessage({ id: "experience_desktop_text3", defaultMessage: " Introducing Nebula for PC, the software that will upgrade your PC experience to a whole different level." })} </div>
            </div>
            <div className={style.what}>
                <div className={style.whatTitle}> {intl.formatMessage({ id: "what_desktop", defaultMessage: " What Is Virtual Desktop" })}</div>
                <div className={style.whatImg}>
                    <div className={style.whatImgBox} style={{ backgroundImage: `url(${desktopPic})` }}></div>
                </div>
            </div>
            <div className={style.use}>
                {useList.map((item, index) => (
                    <div className={style.useItem} key={`useItem${index}`} >
                        <div className={style.useItemBox} style={{ backgroundImage: `url(${item.img})` }}>
                            <div className={style.useItemBoxCover}>
                                {item.text}
                            </div>
                        </div>
                    </div>
                ))}
            </div>


            <div className={style.comp}>
                <div className={style.compatibilityTitle}>{intl.formatMessage({ id: "support_how_connect", defaultMessage: "Compatibility" })}</div>
                <CompatibilitySupport list={compList} />
            </div>
            {/* <CheckDevice  /> */}
            <DownloadBox type='pc' />
        </div>
    )
}
export default VirtualD